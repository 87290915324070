<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Binance integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <!-- end card -->

        <div class="row justify-content-evenly">

          <!-- Base Example -->
          <div class="accordion" id="default-accordion-example">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Шаг 1. Начало работы на бирже Binance
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Биржа может вносить свои визуальные изменения.
                    Внимательно читайте советы и подсказки на самой бирже!
                  </div>
                  <p>Первым шагом является создание учетной записи на
                    <a href="https://www.binance.com/en/activity/referral-entry/CPA?ref=CPA_00BKJ0VYT8" target="_blank">Binance</a>.
                    Обычно это занимает от 1 до 3 дней.
                  </p>
                  <p>После регистрации вам необходимо будет пройти верификацию для подтверждения вашей личности.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Шаг 2. Пополните свой счет на бирже
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Для торговли на бирже вам понадобится <strong>USDT</strong>.
                  </div>
                  <p>
                    Чтобы наша система могла совершать транзакции, на вашем счете должен быть USDT.
                    <br>
                    У вас есть 2 варианта:
                  </p>
                  <ol>
                    <li>Переведите активы (USDT) с другой биржи или кошелька, перейдя по этой. <a href="https://www.binance.com/en/my/wallet/account/main/deposit/crypto" target="_blank">ссылке</a>;</li>
                    <li>Купите желаемый актив (USDT), нажав на эту <a href="https://www.binance.com/en/crypto/buy/" target="_blank">ссылку</a>.</li>
                  </ol>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Следуйте инструкциям на Binance, чтобы выполнить все шаги!
                  </div>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Обратите внимание, что ваши активы должны храниться в аккаунте, с которым работает конкретный бот нашей системы.
                    Для спотового бота это спотовый счет, а для фьючерсного бота — фьючерсный счет.
                  </div>
                  <p>Если все было сделано правильно, вы увидите свои активы, нажав на эту <a href="https://www.binance.com/en/my/wallet/account/overview">ссылку</a>.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  Шаг 3. Создайте ключ API
                </button>
              </h2>
              <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-warning" role="alert">
                    <i class="las la-info-circle"></i>
                    Мы настоятельно рекомендуем вам включить двухфакторную аутентификацию.
                  </div>
                  <div class="alert alert-warning" role="alert">
                    <i class="las la-info-circle"></i>
                    Мы настоятельно рекомендуем вам использовать суб-аккаунт, чтобы не смешивать свои личные активы с активами которые купит бот.
                  </div>
                  <p>Чтобы создать суб-аккаунт, перейдите по <a href="https://www.binance.com/en/my/sub-account/account-management" target="_blank">ссылке</a>.</p>

                  <img src="/Instruction/Binance/create-sub-account.png" class="img-fluid border-dotted" />
                  <br><br>
                  <p>Чтобы создать ключ API, перейдите по <a href="https://www.binance.com/en/my/sub-account/api-management" target="_blank">ссылке</a>. </p>
                  <img src="/Instruction/Binance/create-api-key-main.png" class="img-fluid border-dotted"/>
                  <br><br>
                  <p>Выберете "System Generated".</p>

                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Для интеграции необходимо отметить: <strong>Enable Reading</strong>, <strong>Enable Spot & Margin Trading</strong> and <strong>Restrict access to trusted IPs only (Recommended)</strong>.
                  </div>
                  <br>

                  <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    В целях безопасности убедитесь, что вы добавили IP-адрес нашего сервера. <strong>52.28.48.36</strong>. Поле: <strong>Restrict access to trusted IPs only (Recommended)</strong>
                  </div>

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  Шаг 4. Интеграция с нашей системой
                </button>
              </h2>
              <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <p>Перейдите по указанной <a href="/api-keys" target="_blank">ссылке</a> для того что бы добавить API ключ в нашу систему.</p>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Чтобы заполнить все обязательные поля, используйте данные из предыдущего шага.
                  </div>
                  <img src="/Instruction/Binance/j2p-add-api-key.png" class="img-fluid border-dotted col-md-6"/>
                  <br><br>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  Шаг 5. Создайте бота в нашей системе
                </button>
              </h2>
              <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Внимательно ознакомьтесь с информацией на странице создания бота!
                  </div>
                  <p>Для создания торгового бота, перейдите по указанной <a href="/bots" target="_blank">ссылке</a> </p>

                  <img src="/Instruction/Binance/j2p-create-bot.png" class="img-fluid border-dotted col-md-6" />
                  <br>
                  <br>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Сумма вашего депозита влияет на количество активных сделок и сумму сделок.
                  </div>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Мы рекомендуем иметь как можно больше активных сделок.
                  </div>
                  <p>Убедитесь, что <a href="/bots" target="_blank">Ваши боты</a> активированы.</p>
                  <img src="/Instruction/Binance/bots-list.png" class="img-fluid border-dotted col-md-7" />
                </div>
              </div>
            </div>
          </div><!--end col-->.
        </div><!--end row-->
      </div><!--end row-->
    </div><!--end row-->
  </Layout>
</template>
